<template>
  <div class="tw-min-h-screen tw-bg-white tw-flex">
    <div
      class="
        tw-flex-1 tw-flex tw-flex-col tw-justify-center tw-py-12 tw-px-4
        sm:tw-px-6
        lg:tw-flex-none lg:tw-px-20
        xl:tw-px-24
      "
    >
      <div class="tw-mx-auto tw-w-full tw-max-w-sm lg:tw-w-96">
        <div>
          <img
            class="tw-h-12 tw-w-auto"
            src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
            alt="Workflow"
          />
          <h2 class="tw-mt-6 tw-text-3xl tw-font-extrabold tw-text-gray-900">
            Sign in to your account
          </h2>
          <p class="tw-mt-2 tw-text-sm tw-text-gray-600">
            Or
            {{ " " }}
            <a
              href="#"
              class="tw-font-medium tw-text-indigo-600 hover:tw-text-indigo-500"
            >
              start your 14-day free trial
            </a>
          </p>
        </div>

        <div class="tw-mt-8">
          <div>
            <div>
              <p class="tw-text-sm tw-font-medium tw-text-gray-700">
                Sign in with
              </p>

              <div class="tw-mt-1 tw-grid tw-grid-cols-3 tw-gap-3">
                <div>
                  <a
                    href="#"
                    class="
                      tw-w-full
                      tw-inline-flex
                      tw-justify-center
                      tw-py-2
                      tw-px-4
                      tw-border
                      tw-border-gray-300
                      tw-rounded-md
                      tw-shadow-sm
                      tw-bg-white
                      tw-text-sm
                      tw-font-medium
                      tw-text-gray-500
                      hover:tw-bg-gray-50
                    "
                  >
                    <span class="tw-sr-only">Sign in with Facebook</span>
                    <svg
                      class="tw-w-5 tw-h-5"
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </a>
                </div>

                <div>
                  <a
                    href="#"
                    class="
                      tw-w-full
                      tw-inline-flex
                      tw-justify-center
                      tw-py-2
                      tw-px-4
                      tw-border
                      tw-border-gray-300
                      tw-rounded-md
                      tw-shadow-sm
                      tw-bg-white
                      tw-text-sm
                      tw-font-medium
                      tw-text-gray-500
                      hover:tw-bg-gray-50
                    "
                  >
                    <span class="tw-sr-only">Sign in with Twitter</span>
                    <svg
                      class="tw-w-5 tw-h-5"
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84"
                      />
                    </svg>
                  </a>
                </div>

                <div>
                  <a
                    href="#"
                    class="
                      tw-w-full
                      tw-inline-flex
                      tw-justify-center
                      tw-py-2
                      tw-px-4
                      tw-border
                      tw-border-gray-300
                      tw-rounded-md
                      tw-shadow-sm
                      tw-bg-white
                      tw-text-sm
                      tw-font-medium
                      tw-text-gray-500
                      hover:tw-bg-gray-50
                    "
                  >
                    <span class="tw-sr-only">Sign in with GitHub</span>
                    <svg
                      class="tw-w-5 tw-h-5"
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 0C4.477 0 0 4.484 0 10.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0110 4.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.203 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.942.359.31.678.921.678 1.856 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0020 10.017C20 4.484 15.522 0 10 0z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>

            <div class="tw-mt-6 tw-relative">
              <div
                class="tw-absolute tw-inset-0 tw-flex tw-items-center"
                aria-hidden="true"
              >
                <div class="tw-w-full tw-border-t tw-border-gray-300" />
              </div>
              <div class="tw-relative tw-flex tw-justify-center tw-text-sm">
                <span class="tw-px-2 tw-bg-white tw-text-gray-500">
                  Or continue with
                </span>
              </div>
            </div>
          </div>

          <div class="tw-mt-6">
            <form class="tw-space-y-6" @submit.prevent="signIn">
              <div>
                <label
                  for="email"
                  class="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
                >
                  Email address
                </label>
                <div class="tw-mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autocomplete="email"
                    required=""
                    class="
                      tw-appearance-none
                      tw-block
                      tw-w-full
                      tw-px-3
                      tw-py-2
                      tw-border
                      tw-border-gray-300
                      tw-rounded-md
                      tw-shadow-sm
                      tw-placeholder-gray-400
                      focus:tw-outline-none
                      focus:tw-ring-indigo-500
                      focus:tw-border-indigo-500
                      sm:tw-text-sm
                    "
                    v-model="formValues.email"
                  />
                </div>
              </div>

              <div class="tw-space-y-1">
                <label
                  for="password"
                  class="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
                >
                  Password
                </label>
                <div class="tw-mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autocomplete="current-password"
                    required=""
                    class="
                      tw-appearance-none
                      tw-block
                      tw-w-full
                      tw-px-3
                      tw-py-2
                      tw-border
                      tw-border-gray-300
                      tw-rounded-md
                      tw-shadow-sm
                      tw-placeholder-gray-400
                      focus:tw-outline-none
                      focus:tw-ring-indigo-500
                      focus:tw-border-indigo-500
                      sm:tw-text-sm
                    "
                    v-model="formValues.password"
                  />
                </div>
              </div>

              <div class="tw-flex tw-items-center tw-justify-between">
                <div class="tw-flex tw-items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    class="
                      tw-h-4 tw-w-4 tw-text-indigo-600
                      focus:tw-ring-indigo-500
                      tw-border-gray-300 tw-rounded
                    "
                  />
                  <label
                    for="remember-me"
                    class="tw-ml-2 tw-block tw-text-sm tw-text-gray-900"
                  >
                    Remember me
                  </label>
                </div>

                <div class="tw-text-sm">
                  <a
                    href="#"
                    class="
                      tw-font-medium tw-text-indigo-600
                      hover:tw-text-indigo-500
                    "
                  >
                    Forgot your password?
                  </a>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  class="
                    tw-w-full
                    tw-flex
                    tw-justify-center
                    tw-py-2
                    tw-px-4
                    tw-border
                    tw-border-transparent
                    tw-rounded-md
                    tw-shadow-sm
                    tw-text-sm
                    tw-font-medium
                    tw-text-white
                    tw-bg-indigo-600
                    hover:tw-bg-indigo-700
                    focus:tw-outline-none
                    focus:tw-ring-2
                    focus:tw-ring-offset-2
                    focus:tw-ring-indigo-500
                  "
                  @click.prevent="signIn"
                >
                  Sign in
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="tw-hidden lg:tw-block tw-relative tw-w-0 tw-flex-1">
      <img
        class="tw-absolute tw-inset-0 tw-h-full tw-w-full tw-object-cover"
        src="https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
        alt=""
      />
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "Home",
  components: {},
  data() {
    return {
      showPassword: false,
      formValues: {
        email: "",
        password: "",
      },
    };
  },
  methods: {
    signIn() {
      this.login(this.formValues).then(() => {
        this.$router.push({ name: "Home" });
      });
    },
    ...mapActions("account", ["login"]),
  },
};
</script>
